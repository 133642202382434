:root {
  --scrollbar-size: 8px;
  --scrollbar-track-background: rgba(0,0,0,0.4);
  --scrollbar-thumb-background: rgba(200,200,200,0.3);
  --scrollbar-thumb-hover-color: rgba(255,255,255,0.4);

  --default-margin: 1rem;
  --default-font-size: 1rem;
  --default-line-height: 1.2rem;

  --default-font-color: white;
  --secondary-font-color: #888;

  --width-break-md: 400px;

  --default-border-color: #232323;

  --default-post-background: rgba(0,0,0,0.8);

  --hover-color: rgb(63, 63, 115);
  --hover-gradient: linear-gradient(42deg, #232323 0%, rgb(63, 63, 115) 78%, #232323 100%);
}

#animation_toggle {
  top: var(--default-margin);
  right: var(--default-margin);
  position: fixed;

  background-color: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--default-font-color);
  font-size: var(--default-font-size);
}

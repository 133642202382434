details.toc {
  margin-bottom: var(--default-margin);

  summary {
    cursor: pointer;
  }

  ol#toc {
    margin: .2rem;

    display: flex;
    flex-direction: column;

    li {
      font-size: .9rem;
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;

      ol {
        margin: 0;
        padding: 0 0 0 var(--default-margin);
      }
    }
  }
}

article.post {
  margin-top: 16px;

  padding-left: 1rem;
  padding-right: 1rem;

  border: 1px solid var(--hover-color);
  border-image: var(--hover-gradient) 1;
  border-radius: 5px;

  background: var(--default-post-background);

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    & {
      background: rgba(0, 0, 0, 0.3);
      -webkit-backdrop-filter: blur(1px);
      backdrop-filter: blur(1px);
    }
  }

  header.post-header {
    h1.post-title {
      font-size: 2rem;
      line-height: 2.2rem;
      margin-bottom: 0;
    }

    p.post-meta {
      color: #bbbbbb;
      font-size: .8rem;
    }
  }

  .post-content {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    max-width: 900px;
    color: #fff;

    margin-bottom: 16px;

    img {
      max-height: 800px;
      max-width: 100%;
      width: auto;
      height: auto;
      display: block;
      margin: 16px auto;

      &.white-bg {
        background-color: #fff;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 1rem;
      margin-bottom: .4rem;
      width: 100%;
    }

    h1 {
      font-size: 2.2rem;
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.8rem;
    }

    h4 {
      font-size: 1.6rem;
    }

    h5 {
      font-size: 1.4rem;
    }

    h6 {
      font-size: 1.2rem;
    }

    p {
      width: 100%;
      font-size: 1rem;
      line-height: 1.3rem;
      margin-top: .5rem;
      margin-bottom: 1rem;

      position: relative;

      code {
        font-size: 0.9rem;
        border: 1px solid #444;
        border-radius: 5px;
        padding: 0 4px;
        background-color: #333;
      }
    }

    ol, ul {
      box-sizing: border-box;
      padding: .5rem 1rem;
      width: 100%;
      margin-top: .5rem;
      margin-bottom: .5rem;
      margin-left: 2rem;

      li {
        font-size: 1rem;
        margin-top: .3rem;
        margin-bottom: .3rem;

        > ol, > ul {
          margin-top: .3rem;
          margin-bottom: .3rem;
          margin-left: .3rem;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    > div {
      width: 100%;
    }

    pre {
      padding: 8px;

      overflow: auto;
      max-width: 100%;

      border: 1px solid gray;
      background-color: rgba(100, 100, 100, 0.2);
    }

    iframe {
      margin: 16px auto;
    }
  }
}

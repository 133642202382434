main.page-content {
  max-width: 800px;

  @media screen and (max-width: 300px) {
    & {
      max-width: 280px;
    }
  }

  @media screen and (max-width: 400px) and (min-width: 300px) {
    & {
      max-width: 300px;
    }
  }

  @media screen and (max-width: 500px) and (min-width: 400px) {
    & {
      max-width: 400px;
    }
  }

  @media screen and (max-width: 600px) and (min-width: 500px) {
    & {
      max-width: 500px;
    }
  }

  @media screen and (max-width: 800px) and (min-width: 600px) {
    & {
      max-width: 600px;
    }
  }

  @media screen and (min-width: 1000px) {
    & {
      max-width: 900px;
    }
  }
}

form.search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  label {
    display: none;
  }
  input#search-box,
  input[type='submit'] {
    padding: 8px;
    background-color: var(--default-post-background);
    appearance: none;
    color: var(--default-font-color);

    border: 1px solid var(--default-border-color);

    &:hover {
      border-color: var(--hover-color);
      border-image: var(--hover-gradient) 1;
    }

    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  input[type='submit'] {
    cursor: pointer;
    font-size: .8rem;
  }
}

#search-results {
  li.search-error {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;

    color: rgb(255, 200, 200);
    border-color: rgb(50, 10, 10);
  }
}
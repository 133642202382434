.highlight {
  background-color: var(--default-post-background);
  color: var(--default-font-color);
}

/* Comment */
.highlight .c {
  color: #586e75
}

/* Error */
.highlight .err {
  color: #93a1a1
}

/* Generic */
.highlight .g {
  color: #93a1a1
}

/* Keyword */
.highlight .k {
  color: #859900
}

/* Literal */
.highlight .l {
  color: #93a1a1
}

/* Name */
.highlight .n {
  color: #93a1a1
}

/* Operator */
.highlight .o {
  color: #859900
}

/* Other */
.highlight .x {
  color: #cb4b16
}

/* Punctuation */
.highlight .p {
  color: #93a1a1
}

/* Comment.Multiline */
.highlight .cm {
  color: #586e75
}

/* Comment.Preproc */
.highlight .cp {
  color: #859900
}

/* Comment.Single */
.highlight .c1 {
  color: #586e75
}

/* Comment.Special */
.highlight .cs {
  color: #859900
}

/* Generic.Deleted */
.highlight .gd {
  color: #2aa198
}

/* Generic.Emph */
.highlight .ge {
  color: #93a1a1;
  font-style: italic
}

/* Generic.Error */
.highlight .gr {
  color: #dc322f
}

/* Generic.Heading */
.highlight .gh {
  color: #cb4b16
}

/* Generic.Inserted */
.highlight .gi {
  color: #859900
}

/* Generic.Output */
.highlight .go {
  color: #93a1a1
}

/* Generic.Prompt */
.highlight .gp {
  color: #93a1a1
}

/* Generic.Strong */
.highlight .gs {
  color: #93a1a1;
  font-weight: bold
}

/* Generic.Subheading */
.highlight .gu {
  color: #cb4b16
}

/* Generic.Traceback */
.highlight .gt {
  color: #93a1a1
}

/* Keyword.Constant */
.highlight .kc {
  color: #cb4b16
}

/* Keyword.Declaration */
.highlight .kd {
  color: #268bd2
}

/* Keyword.Namespace */
.highlight .kn {
  color: #859900
}

/* Keyword.Pseudo */
.highlight .kp {
  color: #859900
}

/* Keyword.Reserved */
.highlight .kr {
  color: #268bd2
}

/* Keyword.Type */
.highlight .kt {
  color: #dc322f
}

/* Literal.Date */
.highlight .ld {
  color: #93a1a1
}

/* Literal.Number */
.highlight .m {
  color: #2aa198
}

/* Literal.String */
.highlight .s {
  color: #2aa198
}

/* Name.Attribute */
.highlight .na {
  color: #93a1a1
}

/* Name.Builtin */
.highlight .nb {
  color: #B58900
}

/* Name.Class */
.highlight .nc {
  color: #268bd2
}

/* Name.Constant */
.highlight .no {
  color: #cb4b16
}

/* Name.Decorator */
.highlight .nd {
  color: #268bd2
}

/* Name.Entity */
.highlight .ni {
  color: #cb4b16
}

/* Name.Exception */
.highlight .ne {
  color: #cb4b16
}

/* Name.Function */
.highlight .nf {
  color: #268bd2
}

/* Name.Label */
.highlight .nl {
  color: #93a1a1
}

/* Name.Namespace */
.highlight .nn {
  color: #93a1a1
}

/* Name.Other */
.highlight .nx {
  color: #93a1a1
}

/* Name.Property */
.highlight .py {
  color: #93a1a1
}

/* Name.Tag */
.highlight .nt {
  color: #268bd2
}

/* Name.Variable */
.highlight .nv {
  color: #268bd2
}

/* Operator.Word */
.highlight .ow {
  color: #859900
}

/* Text.Whitespace */
.highlight .w {
  color: #93a1a1
}

/* Literal.Number.Float */
.highlight .mf {
  color: #2aa198
}

/* Literal.Number.Hex */
.highlight .mh {
  color: #2aa198
}

/* Literal.Number.Integer */
.highlight .mi {
  color: #2aa198
}

/* Literal.Number.Oct */
.highlight .mo {
  color: #2aa198
}

/* Literal.String.Backtick */
.highlight .sb {
  color: #586e75
}

/* Literal.String.Char */
.highlight .sc {
  color: #2aa198
}

/* Literal.String.Doc */
.highlight .sd {
  color: #93a1a1
}

/* Literal.String.Double */
.highlight .s2 {
  color: #2aa198
}

/* Literal.String.Escape */
.highlight .se {
  color: #cb4b16
}

/* Literal.String.Heredoc */
.highlight .sh {
  color: #93a1a1
}

/* Literal.String.Interpol */
.highlight .si {
  color: #2aa198
}

/* Literal.String.Other */
.highlight .sx {
  color: #2aa198
}

/* Literal.String.Regex */
.highlight .sr {
  color: #dc322f
}

/* Literal.String.Single */
.highlight .s1 {
  color: #2aa198
}

/* Literal.String.Symbol */
.highlight .ss {
  color: #2aa198
}

/* Name.Builtin.Pseudo */
.highlight .bp {
  color: #268bd2
}

/* Name.Variable.Class */
.highlight .vc {
  color: #268bd2
}

/* Name.Variable.Global */
.highlight .vg {
  color: #268bd2
}

/* Name.Variable.Instance */
.highlight .vi {
  color: #268bd2
}

/* Literal.Number.Integer.Long */
.highlight .il {
  color: #2aa198
}

ul.post-list {
  padding: 0;
  margin: 0;

  list-style-type: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 1rem;
    width: 100%;

    margin: 16px 0;

    border: 1px solid var(--default-border-color);
    border-radius: 5px;

    &:hover {
      border-color: var(--hover-color);
      border-image: var(--hover-gradient) 1;
    }

    background: var(--default-post-background);

    text-align: center;

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      & {
        background: rgba(0,0,0,0.3);
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
      }
    }

    > a {
      width: 100%;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;

      h2, p, span {
        text-align: start;
      }

      h2 {
        font-size: 1.2rem;
        margin-top: .9rem;
        margin-bottom: 0;
      }

      p {
        font-size: .9rem;
        margin-top: .9rem;
        margin-bottom: .9rem;
      }

      span.meta {
        color: gray;
        font-size: .8rem;
      }
    }
  }
}
div#fof {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    margin: 10px auto;
    max-width: 600px;
    text-align: center;

    h1 {
      margin: 30px 0;
      font-size: 4rem;
      line-height: 1;
      letter-spacing: -1px;
    }

    p {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 0.8rem;

      &:first-of-type {
        font-size: 1.2rem;
      }
    }
  }
}
footer.footer {
  width: 100%;
  max-width: 800px;

  margin-top: var(--default-margin);
  margin-bottom: var(--default-margin);

  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  font-size: .8rem;

  @media (max-width: var(--width-break-md)) {
    min-height: 6rem;
    flex-direction: column;
  }

  a {
    &, &:visited {
      color: var(--secondary-font-color);
    }
  }
}
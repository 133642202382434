header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: var(--default-margin);

  h1.title {
    font-weight: 200;
    font-size: 3rem;

    a {
      text-decoration: none;

      &:visited {
        color: white;
      }
    }
  }
  p.subtitle {
    font-size: var(--default-font-size);
    font-weight: 700;
    margin-top: 0;
  }
}

@use "styles/variables";
@use "styles/layout";
@use "styles/media";
@use "styles/links";
@use "styles/not-found";
@use "styles/header";
@use "styles/nav";
@use "styles/search";
@use "styles/pagination";
@use "styles/post-list";
@use "styles/footer";
@use "styles/post";
@use "styles/syntax";
@use "styles/animation-toggle";
@use "styles/videojs";
@use "styles/toc";

::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track-background);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-background);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}

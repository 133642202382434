ul.pagination {
  list-style-type: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: calc(var(--default-margin) * 2) 0;
  padding: 0;

  width: 100%;

  li {
    margin: 0 calc(var(--default-margin) / 4);
    padding: 0;

    font-size: var(--default-font-size);

    &.edge {
      color: var(--secondary-font-color);
    }

    a, span {
      cursor: pointer;

      color: var(--default-font-color);
      text-decoration: none;

      padding: var(--default-margin);
      vertical-align: middle;
      text-align: center;

      border: 1px solid transparent;

      &:hover {
        border: 1px solid var(--hover-color);
        border-image: var(--hover-gradient) 1;
      }

      &:visited {
        color: var(--default-font-color);
        text-decoration: none;
      }
    }

    &.current {
      color: var(--secondary-font-color);

      a, span {
        border: 1px solid var(--hover-color);
        border-image: var(--hover-gradient) 1;

        border-left: none;
        border-right: none;
        border-top: none;
      }
    }
  }
}